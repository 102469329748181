import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { Box, Button, Grid, Typography } from '@mui/material';
import {
  Fragment,
  FunctionComponent,
  ReactNode,
  useRef,
  useEffect,
} from 'react';

import {
  CategoryToColumnsMap,
  ColumnCategories,
  ColumnCategory,
  ColumnCategoryDescription,
  ColumnCategoryJA,
  ColumnCategoryJAShort,
  COLUMN_LIST_PAGE,
} from '@/constants';
import * as user from '@/domain/middlewares/user';
import { State } from '@/types';
import { PageTitle } from '@/view/components/PageTitle';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { FONT_SIZES } from '@/view/styling/theme';

export const ColumnList: FunctionComponent<{ state: State }> = ({
  state: { columnListCategory: columnListCategory },
}) => {
  // FIXME: add all to ColumnCategories, or don't share this state and create state for filter options
  const filterButtonRefs = useRef<(HTMLDivElement | null)[]>([]);
  const defaultFilterButtonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const activeIndex = ColumnCategories.findIndex(
      (category) => columnListCategory === category
    );
    const activeCategoryElement =
      activeIndex >= 0 && filterButtonRefs.current[activeIndex]
        ? filterButtonRefs.current[activeIndex]
        : defaultFilterButtonRef.current;
    activeCategoryElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [columnListCategory, ColumnCategories]);
  return (
    <Grid container sx={{ mb: 5 }}>
      <Grid item xs={12}>
        <PageTitle text={COLUMN_LIST_PAGE.PAGE_TITLE} />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                height: '60px',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  left: '16px',
                  overflow: 'auto',
                  position: 'absolute',
                  py: 2,
                  right: '6px',
                }}
              >
                <Grid container flexWrap="nowrap" gap={'12px'}>
                  <Grid item flex="0 0 auto" ref={defaultFilterButtonRef}>
                    <FilterButton
                      isActive={columnListCategory === null}
                      category={null}
                      key="all"
                    >
                      すべて
                    </FilterButton>
                  </Grid>
                  {ColumnCategories.map((category, index) => {
                    const isActive = columnListCategory === category;
                    return (
                      <Grid
                        item
                        flex="0 0 auto"
                        ref={(el) => (filterButtonRefs.current[index] = el)}
                        key={category}
                      >
                        <FilterButton isActive={isActive} category={category}>
                          {ColumnCategoryJAShort[category]}
                        </FilterButton>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
            {/* FIXME: hack for overflow-x issue with filter above */}
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                display: 'block',
                mx: 2,
                my: 4,
              }}
              fontSize={20}
              fontWeight="bold"
            >
              {columnListCategory
                ? ColumnCategoryJA[columnListCategory]
                : 'すべてのコラム'}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}
          >
            <Box sx={{ px: 2 }}>
              <SpeechBubble style={{ borderRadius: 3, width: '100%' }}>
                <Typography>
                  {ColumnCategoryDescription[
                    columnListCategory ?? 'ALL'
                  ].text.join('')}
                </Typography>
              </SpeechBubble>
              <img
                style={{ maxWidth: '290px' }}
                src={'/images/column/kinchan.png'}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {ColumnCategories.filter((category) => {
              // pass all
              if (columnListCategory === null) return true;
              return category === columnListCategory;
            }).map((category) => (
              <Fragment key={category}>
                {columnListCategory === null && (
                  <Typography
                    sx={{
                      display: 'block',
                      mb: 2,
                      mx: 2,
                    }}
                    fontSize={16}
                    fontWeight="bold"
                  >
                    {ColumnCategoryJA[category]}
                  </Typography>
                )}
                {CategoryToColumnsMap[category].map((column, i) => (
                  <Box
                    key={i}
                    sx={{
                      bgcolor: 'common.white',
                      borderRadius: '12px',
                      mb: 2,
                      mx: 2,
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={`/images/column/${column.headerImage}.png`}
                      width="100%"
                    />
                    <Box
                      p={2}
                      onClick={() => user.onClickGoToColumn(category, i + 1)}
                    >
                      <Typography
                        sx={{
                          color: 'grey.300',
                          display: 'block',
                          mb: '4px',
                        }}
                        fontSize={12}
                        fontWeight="bold"
                      >
                        {ColumnCategoryJA[category]} No. {i + 1}
                      </Typography>
                      <Typography
                        sx={{
                          display: 'block',
                          mb: '8px',
                        }}
                        fontSize={16}
                        fontWeight="bold"
                      >
                        {column.title}
                      </Typography>
                      <Typography
                        sx={{
                          display: 'block',
                        }}
                        fontSize={14}
                      >
                        {column.sections[0].title}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Fragment>
            ))}
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <CategoryButton
              isActive={columnListCategory === null}
              category={null}
            >
              すべてのコラム
            </CategoryButton>
            {ColumnCategories.map((category, i) => (
              <CategoryButton
                category={category}
                isActive={columnListCategory === category}
                key={category}
              >
                {ColumnCategoryJA[category]}
              </CategoryButton>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CategoryButton: FunctionComponent<{
  category: ColumnCategory | null;
  children?: ReactNode;
  isActive: boolean;
}> = ({ isActive, children, category }) => {
  if (isActive) return null;

  // 'FOOD_AND_INTESTINE',
  // 'EXERCISE_AND_INTESTINE',
  // 'LIFESTYLE_MENTAL',
  // 'INTESTINAL_BACTERIA',
  // 'OTHERS',

  const onClick = () => user.onChangeColumnListCategory(category);
  const icon = (() => {
    if (category === 'INTESTINAL_BACTERIA') {
      return (
        <img
          src="/images/column/icon_kin.svg"
          width={24}
          height={24}
          style={{ display: 'inline-flex' }}
        />
      );
    }

    if (category === 'LIFESTYLE_MENTAL') {
      return (
        <img
          src="/images/column/icon_heart.svg"
          width={24}
          height={24}
          style={{ display: 'inline-flex' }}
        />
      );
    }

    if (category === 'FOOD_AND_INTESTINE') {
      return <RestaurantIcon sx={{ color: 'primary.main' }} />;
    }

    if (category === 'EXERCISE_AND_INTESTINE') {
      return (
        <img
          src="/images/column/icon_run.svg"
          width={24}
          height={24}
          style={{ display: 'inline-flex' }}
        />
      );
    }

    if (category === 'OTHERS') {
      return <SentimentSatisfiedAltIcon sx={{ color: 'primary.main' }} />;
    }

    return (
      <img
        src="/images/column/icon_intestine.svg"
        width={24}
        height={24}
        style={{ display: 'inline-flex' }}
      />
    );
  })();

  return (
    <Box
      sx={{
        bgcolor: 'common.white',
        borderRadius: '12px',
        cursor: 'pointer',
        mb: 2,
        mx: 2,
        overflow: 'hidden',
        px: 2,
        py: 1,
      }}
      onClick={onClick}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item alignItems="center" alignContent="center">
          <Typography sx={{ position: 'relative', top: '3px' }}>
            {icon}
          </Typography>
          <Typography
            sx={{
              bottom: '4px',
              fontSize: FONT_SIZES.sm,
              ml: 1,
              position: 'relative',
            }}
          >
            {children}
          </Typography>
        </Grid>
        <Grid item color="grey.600">
          <ChevronRightIcon sx={{ position: 'relative', top: '3px' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

const FilterButton: FunctionComponent<{
  category: ColumnCategory | null;
  children?: ReactNode;
  isActive: boolean;
}> = ({ isActive, children, category }) => {
  const onClick = () => user.onChangeColumnListCategory(category);
  return (
    <Button
      variant={isActive ? 'contained' : 'outlined'}
      sx={{
        borderRadius: '99999px',
        cursor: 'pointer',
        fontSize: '14px',
        ...(isActive ? {} : { borderColor: 'grey.100', color: 'text.primary' }),
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
