import { Box, Grid, Typography } from '@mui/material';
import HTMLParser from 'react-html-parser';

import { DRINKS_DETAILS } from '@/constants';
import { SpeechBubble } from '@/view/components/SpeechBubble';
import { STYLES, BORDER_RADIUS, theme } from '@/view/styling/theme';

type DrinkDetailsProps = {
  speechBubbleDisplayComponent?: JSX.Element;
};

export function DrinkList({ speechBubbleDisplayComponent }: DrinkDetailsProps) {
  return (
    <Grid container>
      <Grid item xs={12} sx={STYLES.drinkList.listWrapper}>
        <Typography component="h3" sx={STYLES.drinkList.title}>
          {DRINKS_DETAILS.TITLE}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 4,
          marginTop: 2,
        }}
      >
        <Box sx={{ maxWidth: '500px', px: '16px' }}>
          {speechBubbleDisplayComponent && (
            <Box>
              <SpeechBubble
                style={{
                  backgroundColor: '#FFF1E3',
                  borderRadius: BORDER_RADIUS.xl,
                  padding: '20px',
                  width: '100%',
                }}
              >
                {speechBubbleDisplayComponent}
              </SpeechBubble>
            </Box>
          )}
          <Box>
            <img
              src={DRINKS_DETAILS.IMAGE_PATH}
              style={{ height: 'auto', maxWidth: '290px' }}
            />
          </Box>
        </Box>
      </Grid>
      {DRINKS_DETAILS.BODY.map((product, index) => (
        <Grid item xs={12} key={index} sx={{ marginBottom: 3, marginX: 2 }}>
          <Grid
            container
            sx={{ display: 'flex' }}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={4} sx={{ flexDirection: 'row' }}>
              <Box
                sx={{
                  ...STYLES.drinkList.listItemImageWrapper,
                  [theme.breakpoints.up('sm')]: {
                    width: '175px',
                  },
                }}
              >
                <img width="61px" height="103px" src={product.IMAGE_PATH} />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={STYLES.drinkList.listItemWrapper}
              >
                <Grid item xs={12}>
                  <Typography
                    component="h3"
                    sx={STYLES.drinkList.listItemTitle}
                  >
                    {product.TITLE}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="h4"
                    sx={STYLES.drinkList.listItemDescription}
                  >
                    {product.DESCRIPTION}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sx={{ marginX: 2 }}>
        <Typography component="h4" sx={STYLES.drinkList.moreInfo}>
          {HTMLParser(DRINKS_DETAILS.MORE_INFO)}
        </Typography>
      </Grid>
    </Grid>
  );
}
