import { Box, Grid, Typography } from '@mui/material';

import { DiscountLabel } from '@/view/components/DiscountLabel';
import { ShippingLabel } from '@/view/components/ShippingLabel';
import { STYLES, theme } from '@/view/styling/theme';

type Props = {
  onClick: (target: string) => void;
  products: {
    DETAIL_PAGE: string;
    IMAGE_PATH: string;
    PRICE: {
      DISCOUNT?: {
        TEXT?: string;
        VALUE: string;
      };
      PURCHASE_FREQUENCY?: string;
      SHIPPING_PRICE?: string;
      VALUE: string;
    }[];
    SUBTITLE: string;
    TITLE: string;
  }[];
  sectionTitle: string;
  taxText: string;
};

export function ProductDetailItem({
  sectionTitle,
  taxText,
  products,
  onClick,
}: Props) {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: 3, marginTop: 5, marginX: 2 }}>
        <Typography component="h3" sx={STYLES.productDetailItem.sectionTitle}>
          {sectionTitle}
        </Typography>
      </Grid>
      {products.map((product, index) => (
        <Grid
          item
          xs={12}
          key={index}
          sx={{ cursor: 'pointer', marginBottom: 3, marginX: 2 }}
          onClick={() => onClick(`/products/${product.DETAIL_PAGE}`)}
        >
          <Grid
            container
            sx={{ display: 'flex' }}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={4}>
              <Box
                component="img"
                src={product.IMAGE_PATH}
                sx={{
                  height: '64px',
                  width: '100px',
                  [theme.breakpoints.up('sm')]: {
                    height: '112px',
                    width: '175px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid container gap={1}>
                <Grid item xs={12}>
                  <Typography
                    component="h4"
                    sx={STYLES.productDetailItem.subTitle}
                  >
                    {product.SUBTITLE}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="h3"
                    sx={STYLES.productDetailItem.title}
                  >
                    {product.TITLE}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {product.PRICE.map((price, index) => (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sx={STYLES.productDetailItem.priceSectionWrapper}
                        gap={'10px'}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            ...STYLES.productDetailItem.priceItemWrapper,
                            marginLeft: price.PURCHASE_FREQUENCY
                              ? '-8px'
                              : '0px',
                          }}
                        >
                          <span
                            style={
                              STYLES.productDetailItem.purchaseFrequencyText
                            }
                          >
                            {price.PURCHASE_FREQUENCY}
                          </span>
                          {price.VALUE}
                          <span
                            style={STYLES.productDetailItem.purchaseTaxText}
                          >
                            ({taxText})
                          </span>
                        </Typography>
                        {price.DISCOUNT && (
                          <DiscountLabel
                            amount={price.DISCOUNT.VALUE}
                            description={price.DISCOUNT.TEXT}
                          />
                        )}
                        {price.SHIPPING_PRICE && (
                          <ShippingLabel amount={price.SHIPPING_PRICE} />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
